<template>
	<div class="ori-mall">
		<mallHeader />
		<div class="mall-content-wraper container">
			<el-row :gutter="20">
				<el-col :span="4"
					><div class="grid-content bg-purple">
						<div class="left">
							<ul>
								<li
									class="fa-angle-right"
									v-for="item in 10"
									:key="item"
									@mouseenter="currentCategory = true"
									@mouseleave="currentCategory = false"
								>
									<a href="#"
										><i class="el-icon-s-cooperation"></i>
										<span class="shap-tag">电脑、手机</span>
									</a>
								</li>
							</ul>
						</div>
						<div class="layer" v-if="currentCategory">
							<h4>
								分类推荐
								<small>根据您的购买或浏览记录推荐</small>
							</h4>
							<!-- 渲染商品 -->
							<ul>
								<li v-for="item in 9" :key="item">
									<RouterLink to="/">
										<img src="@/assets/images/b1.png" alt="" />
										<div class="info">
											<p class="name ellipsis-2">1</p>
											<p class="desc ellipsis">2</p>
											<p class="price"><i>¥</i>3</p>
										</div>
									</RouterLink>
								</li>
							</ul>
						</div>
					</div></el-col
				>
				<el-col :span="16"
					><div class="grid-content bg-purple">
						<div class="middle">
							<el-carousel indicator-position="outside" height="450px">
								<el-carousel-item>
									<img src="@/assets/images/slider-1.jpg" alt="" />
								</el-carousel-item>
								<el-carousel-item>
									<img src="@/assets/images/slider-2.jpg" alt="" />
								</el-carousel-item>
								<el-carousel-item>
									<img src="@/assets/images/slider-3.jpg" alt="" />
								</el-carousel-item>
							</el-carousel>
						</div></div
				></el-col>
				<el-col :span="4" style="padding:0">
					<div class="grid-content bg-purple">
						<div class="right">
							<div class="top">
								<div class="top-left">销量最高</div>
								<div class="top-right">
									<div class="dot1"></div>
									<div class="dot2"></div>
								</div>
							</div>
							<div class="content">
								<div class="shop">
									<div class="shop-left">
										<img src="@/assets/images/f1.jpg" alt="" />
									</div>
									<div class="shop-right">
										<div class="name"><a href="#">平板电脑</a></div>
										<div class="star">
											<ul>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/灰星星.svg" alt="" />
												</li>
											</ul>
										</div>
										<div class="money">
											<span class="now-money">$55.00 </span>
											<span class="old-money">$76.00 </span>
										</div>
									</div>
								</div>
								<div class="shop">
									<div class="shop-left">
										<img src="@/assets/images/f1.jpg" alt="" />
									</div>
									<div class="shop-right">
										<div class="name"><a href="#">平板电脑</a></div>
										<div class="star">
											<ul>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/灰星星.svg" alt="" />
												</li>
											</ul>
										</div>
										<div class="money">
											<span class="now-money">$55.00 </span>
											<span class="old-money">$76.00 </span>
										</div>
									</div>
								</div>
								<div class="shop">
									<div class="shop-left">
										<img src="@/assets/images/f1.jpg" alt="" />
									</div>
									<div class="shop-right">
										<div class="name"><a href="#">平板电脑</a></div>
										<div class="star">
											<ul>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/灰星星.svg" alt="" />
												</li>
											</ul>
										</div>
										<div class="money">
											<span class="now-money">$55.00 </span>
											<span class="old-money">$76.00 </span>
										</div>
									</div>
								</div>
								<div class="shop">
									<div class="shop-left">
										<img src="@/assets/images/f1.jpg" alt="" />
									</div>
									<div class="shop-right">
										<div class="name"><a href="#">平板电脑</a></div>
										<div class="star">
											<ul>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/星星.svg" alt="" />
												</li>
												<li>
													<img src="@/assets/images/icon/灰星星.svg" alt="" />
												</li>
											</ul>
										</div>
										<div class="money">
											<span class="now-money">$55.00 </span>
											<span class="old-money">$76.00 </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="mall-content-middle container">
			<el-row>
				<el-col :span="4"
					><div class="grid-content bg-purple">
						<div class="left">
							<div class="tag">
								<a href="#">
									<img src="@/assets/images/tag2.jpg" alt="" />
								</a>
								<div class="box"></div>
							</div>
							<h3 class="modtitle"><span>最新产品</span></h3>
							<div class="modcontent">
								<div class="content">
									<div class="shop">
										<div class="shop-left">
											<img src="@/assets/images/f1.jpg" alt="" />
										</div>
										<div class="shop-right">
											<div class="name"><a href="#">平板电脑</a></div>
											<div class="star">
												<ul>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/灰星星.svg" alt="" />
													</li>
												</ul>
											</div>
											<div class="money">
												<span class="now-money">$55.00 </span>
												<span class="old-money">$76.00 </span>
											</div>
										</div>
									</div>
									<div class="shop">
										<div class="shop-left">
											<img src="@/assets/images/f1.jpg" alt="" />
										</div>
										<div class="shop-right">
											<div class="name"><a href="#">平板电脑</a></div>
											<div class="star">
												<ul>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/灰星星.svg" alt="" />
													</li>
												</ul>
											</div>
											<div class="money">
												<span class="now-money">$55.00 </span>
												<span class="old-money">$76.00 </span>
											</div>
										</div>
									</div>
									<div class="shop">
										<div class="shop-left">
											<img src="@/assets/images/f1.jpg" alt="" />
										</div>
										<div class="shop-right">
											<div class="name"><a href="#">平板电脑</a></div>
											<div class="star">
												<ul>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/灰星星.svg" alt="" />
													</li>
												</ul>
											</div>
											<div class="money">
												<span class="now-money">$55.00 </span>
												<span class="old-money">$76.00 </span>
											</div>
										</div>
									</div>
									<div class="shop">
										<div class="shop-left">
											<img src="@/assets/images/f1.jpg" alt="" />
										</div>
										<div class="shop-right">
											<div class="name"><a href="#">平板电脑</a></div>
											<div class="star">
												<ul>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/灰星星.svg" alt="" />
													</li>
												</ul>
											</div>
											<div class="money">
												<span class="now-money">$55.00 </span>
												<span class="old-money">$76.00 </span>
											</div>
										</div>
									</div>
								</div>
								<div class="top-right">
									<div class="dot1"></div>
									<div class="dot2"></div>
								</div>
							</div>
							<div class="module">
								<img src="@/assets/images/call-us.jpg" alt="" />
								<div class="box"></div>
								<div class="module-items">
									<div class="module-item">
										<img src="@/assets/images/icon/文档.svg" alt="" />
										<div class="module-text">
											<a href="#">免费送货</a>
											<span>订单金额超过$49.86</span>
										</div>
									</div>
									<div class="module-item">
										<img src="@/assets/images/icon/文档.svg" alt="" />
										<div class="module-text">
											<a href="#">为了保护</a>
											<span>获得信息</span>
										</div>
									</div>
									<div class="module-item">
										<img src="@/assets/images/icon/文档.svg" alt="" />
										<div class="module-text">
											<a href="#">促销礼品</a>
											<span>特别优惠!</span>
										</div>
									</div>
									<div class="module-item module-border">
										<img src="@/assets/images/icon/文档.svg" alt="" />
										<div class="module-text">
											<a href="#">钱要回来</a>
											<span>超过30天退货</span>
										</div>
									</div>
								</div>
							</div>
							<h3 class="modtitle"><span>推荐</span></h3>
							<div class="product-item2">
								<a href="#"
									><img src="@/assets/images/h1.jpg" alt=""/>
									<img src="@/assets/images/h2.jpg" alt="" class="active"
								/></a>
								<div class="star">
									<ul>
										<li>
											<img src="@/assets/images/icon/星星.svg" alt="" />
										</li>
										<li>
											<img src="@/assets/images/icon/星星.svg" alt="" />
										</li>
										<li>
											<img src="@/assets/images/icon/星星.svg" alt="" />
										</li>
										<li>
											<img src="@/assets/images/icon/星星.svg" alt="" />
										</li>
										<li>
											<img src="@/assets/images/icon/灰星星.svg" alt="" />
										</li>
									</ul>
								</div>
								<h4>
									<a href="product.html" title="Pastrami bacon" target="_self"
										>时尚切换</a
									>
								</h4>
								<div class="money">
									<span class="now-money">$55.00 </span>
									<span class="old-money">$76.00 </span>
								</div>
								<div class="top-right">
									<div class="dot1"></div>
									<div class="dot2"></div>
									<div class="dot2"></div>
								</div>
								<span class="lable">-9%</span>
								<!-- <span class="eye"
									><img src="@/assets/images/icon/眼睛.svg" alt=""
								/></span> -->
								<span class="refresh el-icon-refresh"></span>
								<span class="love el-icon-star-off"></span>
								<span class="addToCart">添加购物车</span>
							</div>
							<h3 class="modtitle"><span>最新的文章</span></h3>
							<div class="cat-warp">
								<a href="#"><img src="@/assets/images/h5.jpg" alt=""/></a>
								<div class="box"></div>
								<div class="cat-warp-item">
									<a href="#">
										被咬的恶魔在亨德利特在vulp nemusa tumps
									</a>
									<div class="cat-warp-data">
										<i class="el-icon-date">2017年12月4日</i>
										<i class="el-icon-chat-round">0 评论</i>
									</div>
								</div>
								<el-divider></el-divider>
								<div class="cat-warp-item">
									<a href="#">
										你永远都不会有好心情
									</a>
									<div class="cat-warp-data">
										<i class="el-icon-date">2017年11月15日</i>
										<i class="el-icon-chat-round">0 评论</i>
									</div>
								</div>
							</div>
							<h3 class="modtitle"><span>奖状</span></h3>
							<div class="slider-testimonials">
								<img src="@/assets/images/user-1.jpg" alt="" />
								<div class="name">王德法</div>
								<p>
									"我们一起坐着，奉献自己，让自己变得高尚，让自己变得高尚"
								</p>
								<div class="top-right">
									<div class="dot1"></div>
									<div class="dot2"></div>
									<div class="dot2"></div>
								</div>
							</div>
							<div class="banner2">
								<img src="@/assets/images/user-2.jpg" alt="" />
								<div class="box"></div>
							</div>
						</div></div
				></el-col>
				<el-col :span="20"
					><div class="grid-content bg-purple-light">
						<div class="right">
							<div class="static-cates">
								<ul>
									<li v-for="item in 5" :key="item">
										<a href="#"><img src="@/assets/images/cat1.jpg" alt=""/></a>
										<div class="box"></div>
									</li>
								</ul>
							</div>
							<div class="modtitle">
								<div class="modtitle-left">
									<span class="flash">限时抢购</span>
									<span class="time-kill">
										<ul>
											<li class="num-time">00</li>
											<li class="name-time">:</li>
											<li class="num-time">00</li>
											<li class="name-time">:</li>
											<li class="num-time">00</li>
											<li class="name-time">:</li>
											<li class="num-time">00</li>
										</ul>
									</span>
								</div>
								<div class="modtitle-right">
									<a href="#">查看所有</a>
									<i class="el-icon-caret-right"></i>
								</div>
							</div>
							<div class="product-items">
								<div class="fnPrev" @click="fnPrev()">
									<i class="el-icon-arrow-left"></i>
								</div>
								<div class="fnNext" @click="fnNext()">
									<i class="el-icon-arrow-right"></i>
								</div>
								<div class="product-item" v-for="item in 6" :key="item">
									<a href="#"
										><img src="@/assets/images/h1.jpg" alt=""/>
										<img src="@/assets/images/h2.jpg" alt="" class="active"
									/></a>
									<div class="star">
										<ul>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/灰星星.svg" alt="" />
											</li>
										</ul>
									</div>
									<h4>
										<a href="product.html" title="Pastrami bacon" target="_self"
											>时尚切换</a
										>
									</h4>
									<div class="money">
										<span class="now-money">$55.00 </span>
										<span class="old-money">$76.00 </span>
									</div>
									<el-progress
										:text-inside="false"
										:stroke-width="13"
										:percentage="70"
									></el-progress>
									<p class="a2">Sold: <b>51</b></p>
									<span class="lable">-9%</span>
									<!-- <span class="eye"
										><img src="@/assets/images/icon/眼睛.svg" alt=""
									/></span> -->
									<span class="refresh el-icon-refresh"></span>
									<span class="love el-icon-star-off"></span>
									<span class="addToCart">添加购物车</span>
								</div>
							</div>
							<div class="product-banner">
								<div class="product-banner1">
									<img src="@/assets/images/banner3.jpg" alt="" />
								</div>
								<div class="box1"></div>
								<div class="product-banner2">
									<img src="@/assets/images/banner4.jpg" alt="" />
								</div>
								<div class="box2"></div>
								<div class="product-banner3">
									<img src="@/assets/images/banner5.jpg" alt="" />
								</div>
								<div class="box3"></div>
							</div>
							<div class="modtitle2">
								<div class="modtitle2-left">
									<span>技术</span>
								</div>
								<div class="modtitle2-right">
									<ul class="item-sub-cat">
										<li>
											<a href="#" title="Smartphone" target="_self"
												>智能手机</a
											>
										</li>
										<li>
											<a href="#" title="Tablets" target="_self">平板电脑</a>
										</li>
										<li>
											<a href="#" title="Computer" target="_self">平板电脑</a>
										</li>
										<li>
											<a href="#" title="Accessories" target="_self"
												>平板电脑</a
											>
										</li>
										<li>
											<a href="#" title="Hitech" target="_self">平板电脑</a>
										</li>
									</ul>
								</div>
							</div>
							<div class="product-items">
								<a href="#">
									<img src="@/assets/images/tab1.jpg" alt="" />
								</a>
								<div class="box"></div>
								<div class="fnPrev1" @click="fnPrev()">
									<i class="el-icon-arrow-left"></i>
								</div>
								<div class="fnNext1" @click="fnNext()">
									<i class="el-icon-arrow-right"></i>
								</div>
								<div class="product-item2" v-for="item in 4" :key="item">
									<a href="#"
										><img src="@/assets/images/h1.jpg" alt=""/>
										<img src="@/assets/images/h2.jpg" alt="" class="active"
									/></a>
									<div class="star">
										<ul>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/灰星星.svg" alt="" />
											</li>
										</ul>
									</div>
									<h4>
										<a href="product.html" title="Pastrami bacon" target="_self"
											>时尚切换</a
										>
									</h4>
									<div class="money">
										<span class="now-money">$55.00 </span>
										<span class="old-money">$76.00 </span>
									</div>
									<span class="lable">-9%</span>
									<!-- <span class="eye"
										><img src="@/assets/images/icon/眼睛.svg" alt=""
									/></span> -->
									<span class="refresh el-icon-refresh"></span>
									<span class="love el-icon-star-off"></span>
									<span class="addToCart">添加购物车</span>
								</div>
							</div>
							<div class="modtitle2">
								<div class="modtitle2-left">
									<span>这是所有的</span>
								</div>
								<div class="modtitle2-right">
									<ul class="item-sub-cat">
										<li>
											<a href="#" title="Smartphone" target="_self"
												>客厅</a
											>
										</li>
										<li>
											<a href="#" title="Tablets" target="_self">浴室</a>
										</li>
										<li>
											<a href="#" title="Computer" target="_self">卧室</a>
										</li>
										<li>
											<a href="#" title="Accessories" target="_self"
												>配件</a
											>
										</li>
										<li>
											<a href="#" title="Hitech" target="_self">装饰</a>
										</li>
									</ul>
								</div>
							</div>
							<div class="product-items">
								<div class="fnPrev2" @click="fnPrev()">
									<i class="el-icon-arrow-left"></i>
								</div>
								<div class="fnNext2" @click="fnNext()">
									<i class="el-icon-arrow-right"></i>
								</div>
								<div class="product-item2" v-for="item in 4" :key="item">
									<a href="#"
										><img src="@/assets/images/h1.jpg" alt=""/>
										<img src="@/assets/images/h2.jpg" alt="" class="active"
									/></a>
									<div class="star">
										<ul>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/灰星星.svg" alt="" />
											</li>
										</ul>
									</div>
									<h4>
										<a href="product.html" title="Pastrami bacon" target="_self"
											>时尚切换</a
										>
									</h4>
									<div class="money">
										<span class="now-money">$55.00 </span>
										<span class="old-money">$76.00 </span>
									</div>
									<span class="lable">-9%</span>
									<!-- <span class="eye"
										><img src="@/assets/images/icon/眼睛.svg" alt=""
									/></span> -->
									<span class="refresh el-icon-refresh"></span>
									<span class="love el-icon-star-off"></span>
									<span class="addToCart">添加购物车</span>
								</div>
								<div class="box1"></div>
								<a href="#">
									<img src="@/assets/images/tab1.jpg" alt="" />
								</a>
							</div>
							<div class="modtitle2">
								<div class="modtitle2-left">
									<span>时尚、配件</span>
								</div>
								<div class="modtitle2-right">
									<ul class="item-sub-cat">
										<li>
											<a href="#" title="Smartphone" target="_self"
												>智能手机</a
											>
										</li>
										<li>
											<a href="#" title="Tablets" target="_self">平板电脑</a>
										</li>
										<li>
											<a href="#" title="Computer" target="_self">电脑</a>
										</li>
										<li>
											<a href="#" title="Accessories" target="_self"
												>电脑</a
											>
										</li>
										<li>
											<a href="#" title="Hitech" target="_self">Hitech</a>
										</li>
									</ul>
								</div>
							</div>
							<div class="product-items">
								<div class="fnPrev1" @click="fnPrev()">
									<i class="el-icon-arrow-left"></i>
								</div>
								<div class="fnNext1" @click="fnNext()">
									<i class="el-icon-arrow-right"></i>
								</div>
								<div class="box"></div>
								<a href="#">
									<img src="@/assets/images/tab1.jpg" alt="" />
								</a>
								<div class="product-item2" v-for="item in 4" :key="item">
									<a href="#"
										><img src="@/assets/images/h1.jpg" alt=""/>
										<img src="@/assets/images/h2.jpg" alt="" class="active"
									/></a>
									<div class="star">
										<ul>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/星星.svg" alt="" />
											</li>
											<li>
												<img src="@/assets/images/icon/灰星星.svg" alt="" />
											</li>
										</ul>
									</div>
									<h4>
										<a href="product.html" title="Pastrami bacon" target="_self"
											>时尚切换</a
										>
									</h4>
									<div class="money">
										<span class="now-money">$55.00 </span>
										<span class="old-money">$76.00 </span>
									</div>
									<span class="lable">-9%</span>
									<!-- <span class="eye"
										><img src="@/assets/images/icon/眼睛.svg" alt=""
									/></span> -->
									<span class="refresh el-icon-refresh"></span>
									<span class="love el-icon-star-off"></span>
									<span class="addToCart">添加购物车</span>
								</div>
							</div>
							<div class="btn-image">
								<div class="box1"></div>
								<a href="#">
									<img src="@/assets/images/bn1.jpg" alt="" />
								</a>
								<div class="box2"></div>
								<a href="#">
									<img src="@/assets/images/bn1.jpg" alt="" />
								</a>
							</div>
							<el-tabs v-model="activeName">
								<el-tab-pane label="最好的卖家" name="first">
									<div class="product-items">
										<div class="fnPrev" @click="fnPrev()">
											<i class="el-icon-arrow-left"></i>
										</div>
										<div class="fnNext" @click="fnNext()">
											<i class="el-icon-arrow-right"></i>
										</div>
										<div class="product-item2" v-for="item in 5" :key="item">
											<a href="#"
												><img src="@/assets/images/h1.jpg" alt=""/>
												<img src="@/assets/images/h2.jpg" alt="" class="active"
											/></a>
											<div class="star">
												<ul>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/星星.svg" alt="" />
													</li>
													<li>
														<img src="@/assets/images/icon/灰星星.svg" alt="" />
													</li>
												</ul>
											</div>
											<h4>
												<a
													href="product.html"
													title="Pastrami bacon"
													target="_self"
													>时尚切换</a
												>
											</h4>
											<div class="money">
												<span class="now-money">$55.00 </span>
												<span class="old-money">$76.00 </span>
											</div>
											<span class="lable">-9%</span>
											<!-- <span class="eye"
												><img src="@/assets/images/icon/眼睛.svg" alt=""
											/></span> -->
											<span class="refresh el-icon-refresh"></span>
											<span class="love el-icon-star-off"></span>
											<span class="addToCart">添加购物车</span>
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane label="新来者" name="second"
									>配置管理</el-tab-pane
								>
								<el-tab-pane label="大多数评级" name="third"
									>角色管理</el-tab-pane
								>
							</el-tabs>
							<div class="slider-brands">
								<div class="owl2-stage-outer" ref="rampage">
									<div class="fnPrev" @click="fnPrev()">
										<i class="el-icon-arrow-left"></i>
									</div>
									<div class="fnNext" @click="fnNext()">
										<i class="el-icon-arrow-right"></i>
									</div>
									<div class="fixedBox" :ref="`fixedBox`">
										<div
											class="centerScroll"
											:style="
												`width:${196 *
													progressList.length}px;transform:translate(${scrollResultWidth}px,0);transition:1s;`
											"
										>
											<div
												class="signleTab"
												v-for="(item, index) in progressList"
												:key="index"
											>
												<a href="#"
													><img src="@/assets/images/b1.png" alt="" />
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import mallHeader from './header.vue'
export default {
	name: 'ori-mall',
	components: {
		mallHeader,
	},
	data() {
		return {
			activeName: 'first',
			scrollResultWidth: 0, //transform滚动的距离
			progressList: [
				{ type: 1 },
				{ type: 2 },
				{ type: 3 },
				{ type: 4 },
				{ type: 5 },
				{ type: 6 },
				{ type: 7 },
			],
			currentCategory: false,
      
		}
	},
	created() {},
	methods: {
		//translate的宽度
		// fnScrollWidth(type) {
		// 	let result = 0
		// 	if (type === 'reduce') {
		// 		result = 196
		// 	} else if (type === 'add') {
		// 		result = -196
		// 	} else {
		// 		result = 0
		// 	}
		// 	this.scrollResultWidth += result
		// },
		fnPrev() {
			let prev = [...this.progressList]
			prev.unshift(prev.pop())
			this.progressList = prev
			// console.log(this.progressList);
			// this.fnScrollWidth('reduce')
		},
		fnNext() {
			// this.progressList[this.progressList.length] = this.progressList.shift()
			let next = [...this.progressList]
			next.push(next.shift())
			this.progressList = next
			// console.log(this.progressList);
			// this.fnScrollWidth('add')
		},
	},
}
</script>

<style lang="scss" scoped>
.ori-mall {
  font-family: fangsong;
	height: 2000px;
	::v-deep {
		.el-progress-bar__inner {
			background-color: #005ea6;
		}
		.el-tabs__nav-wrap {
			margin-left: 20px;
		}
	}
	li {
		list-style: none;
	}
  img,.box,.box1,.box2,.box3,span,.fnPrev,.fnNext,.fnNext1,.fnPrev1,.fnNext2,.fnPrev2{
    cursor: pointer;
  }
	.container {
		width: 1200px;
		margin: 0 auto;
	}
	.mall-content-wraper {
		display: flex;
		flex-direction: column;
		.left {
			height: 470px;
			ul {
				width: 200px;
				padding-top: 0;
				background-color: #f5f5f5;
				border-bottom-right-radius: 3px;
				border-bottom-left-radius: 3px;
				li {
					list-style: none;
					cursor: pointer;
					border: none;
					padding: 2.5px 3px;
					position: relative;
					a {
						color: #666;
						display: block;
						font-size: 14px;
						font-weight: 400;
						padding: 10px 0;
						line-height: 22px;
						position: relative;
						text-transform: capitalize;
						background: none;
						height: auto;
						.shap-tag {
							margin-left: 10px;
						}
					}
				}
				li:hover a {
					color: #005ea6;
				}
				.fa-angle-right::after {
					content: '';
					background: url('~@/assets/images/icon/24gl-arrowRight2.svg');
					position: absolute;
					right: 0px;
					top: 12px;
					width: 21px;
					height: 15px;
				}
			}
			// background-color: yellow;
		}
		.layer {
			width: 776px;
			height: 470px;
			background: rgba(255, 255, 255, 0.8);
			position: absolute;
			left: 210px;
			top: 0;
			// display: none;
			z-index: 3;
			padding-left: 15px;
			h4 {
				font-size: 20px;
				font-weight: normal;
				line-height: 80px;
				small {
					font-size: 16px;
					color: #666;
				}
			}
			ul {
				display: flex;
				padding: 0;
				flex-wrap: wrap;
				li {
					width: 243px;
					height: 117px;
					margin-right: 15px;
					margin-bottom: 15px;
					border: 1px solid #eee;
					border-radius: 4px;
					background: #fff;
					&:nth-child(3n) {
						margin-right: 0;
					}
					a {
						display: flex;
						width: 100%;
						height: 100%;
						align-items: center;
						padding: 10px;
						&:hover {
							background: #e3f9f4;
						}
						img {
							width: 95px;
							height: 95px;
						}
						.info {
							padding-left: 10px;
							line-height: 24px;
							width: 140px;
							.name {
								font-size: 16px;
								color: #666;
							}
							.desc {
								color: #999;
							}
							.price {
								font-size: 22px;
								color: blue;
								i {
									font-size: 16px;
								}
							}
						}
					}
				}
				li.brand {
					height: 180px;
					a {
						align-items: flex-start;
						img {
							width: 120px;
							height: 160px;
						}
						.info {
							p {
								margin-top: 8px;
							}
							.place {
								color: #999;
							}
						}
					}
				}
			}
		}
		.middle {
			padding: 20px;
			img {
				width: 755px;
				height: 450px;
			}
		}
		.right {
			height: 450px;
			margin-top: 20px;
			border: 1px solid #ccc;
			// background-color: yellowgreen;
			border-radius: 5px;
			.top {
				height: 40px;
				background-color: #e9ecf1;
				display: flex;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				margin-bottom: 10px;
				.top-left {
					flex: 2;
					// background-color: #ff5e00;
					text-align: center;
					line-height: 40px;
					font-weight: 700;
					font-size: 18px;
				}
				.top-right {
					flex: 1;
					// background-color: #005ea6;
					display: flex;
					justify-content: center;
					align-items: center;
					.dot1 {
						width: 30px;
						height: 8px;
						border-radius: 4px;
						background-color: #005ea6;
						margin-right: 5px;
					}
					.dot2 {
						width: 8px;
						height: 8px;
						background-color: #222;
						border-radius: 8px;
					}
				}
			}
			.content {
				.shop {
					width: 100%;
					height: 90px;
					// background-color: yellow;
					margin-bottom: 10px;
					display: flex;
					.shop-left {
						flex: 1;
					}
					.shop-right {
						flex: 2;
						font-size: 14px;
						.name {
							padding: 5px 0 5px 15px;
						}
						.star {
							ul {
								display: flex;
								padding-left: 15px;
								margin-bottom: 5px;
								li {
									list-style: none;
									img {
										width: 12px;
										height: 12px;
									}
								}
							}
						}
						.money {
							padding-left: 15px;
							.now-money {
								color: #005ea6;
								font-size: 16px;
								font-weight: 600;
							}
							.old-money {
								text-decoration: line-through;
								color: #aaa;
							}
						}
					}
				}
			}
		}
	}
	.mall-content-middle {
		.left {
			width: 100%;
			//   height: 1000px;
			//   background-color: #005ea6;
			img {
				width: 200px;
			}
			.tag {
				position: relative;
				.box {
					width: 200px;
					height: 312px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 5px;
					margin-left: 5px;
					left: -5px;
				}
				.box:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
			}
			.modtitle {
				border-bottom: 2px solid #eee;
				display: inline-block;
				width: 200px;
				position: relative;
				margin-top: 0;
				margin-bottom: 15px;
				margin-top: 20px;
				font-size: 20px;
			}
			.modtitle::after {
				position: absolute;
				content: '';
				width: 110px;
				height: 2px;
				background-color: #005ea6;
				bottom: -2px;
				left: 0;
			}
			.modcontent {
				margin-top: 10px;
				margin-bottom: 20px;
				// background-color: yellowgreen;
				.top-right {
					flex: 1;
					// background-color: #005ea6;
					display: flex;
					// justify-content: center;
					margin-left: 88px;
					align-items: center;
					.dot1 {
						width: 30px;
						height: 8px;
						border-radius: 4px;
						background-color: #005ea6;
						margin-right: 5px;
					}
					.dot2 {
						width: 8px;
						height: 8px;
						background-color: #222;
						border-radius: 8px;
					}
				}
				.content {
					.shop {
						width: 100%;
						height: 90px;
						// background-color: yellow;
						margin-bottom: 10px;
						display: flex;
						.shop-left {
							flex: 1;
							img {
								width: 80px;
								height: 80px;
							}
						}
						.shop-right {
							flex: 2;
							font-size: 14px;
							.name {
								padding: 0 0 0 15px;
							}
							.star {
								ul {
									display: flex;
									padding-left: 15px;
									margin-bottom: 5px;
									li {
										list-style: none;
										img {
											width: 12px;
											height: 12px;
										}
									}
								}
							}
							.money {
								padding-left: 15px;
								.now-money {
									color: #005ea6;
									font-size: 16px;
									font-weight: 600;
								}
								.old-money {
									text-decoration: line-through;
									color: #aaa;
								}
							}
						}
					}
				}
			}
			.module {
				width: 200px;
				// background-color: yellow;
				position: relative;
				.box {
					width: 200px;
					height: 99.19px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 10px;
					left: 0;
				}
				.box:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
				.module-items {
					border: 1px solid #ccc;
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;
					.module-item {
						height: 70px;
						padding-left: 5px;
						// background-color: yellow;
						// padding: 0 20px;
						width: 200px;
						border-bottom: 1px solid #ccc;
						margin-right: 10px;
						display: flex;
						align-items: center;
						img {
							width: 36px;
							height: 36px;
							margin-right: 10px;
						}
						.module-text {
							display: flex;
							flex-direction: column;
							a {
								color: #333;
								font-weight: 600;
							}
							a:hover {
								color: #005ea6;
							}
							span {
								color: #999;
								font-size: 14px;
								margin-top: 3px;
								margin-bottom: 0;
							}
						}
					}
					.module-border {
						border: none;
					}
				}
			}
			.product-item2:hover .active {
				opacity: 0;
				transition: all 0.5s;
			}
			.product-item2:hover .eye {
				opacity: 1;
				animation: change2 0.5s;
			}
			.product-item2:hover .love {
				opacity: 1;
				animation: animLoadedHeader2 0.5s;
			}
			.product-item2:hover .refresh {
				opacity: 1;
				animation: animLoadedHeader1 0.5s;
			}
			.product-item2:hover .addToCart {
				opacity: 1;
				animation: animLoadedHeader3 0.5s;
			}
			.product-item2:hover .star {
				opacity: 0;
			}
			.product-item2:hover h4 {
				opacity: 0;
			}
			.product-item2 {
				position: relative;
				width: 200px;
				text-align: center;
				a {
					img {
						position: relative;
						width: 200px;
						height: 200px;
					}
					.active {
						// opacity: 0;
						position: absolute;
						top: 0;
						right: 0;
					}
				}
				.star {
					ul {
						display: flex;
						padding-left: 70px;
						margin-bottom: 5px;
						li {
							list-style: none;
							img {
								width: 12px;
								height: 12px;
							}
						}
					}
				}
				h4 {
					font-size: 13px;
					color: #333;
				}
				.money {
					margin-bottom: 5px;
					.now-money {
						color: #005ea6;
						font-size: 16px;
						font-weight: 600;
					}
					.old-money {
						text-decoration: line-through;
						color: #aaa;
					}
				}
				.top-right {
					flex: 1;
					// background-color: #005ea6;
					display: flex;
					// justify-content: center;
					margin-left: 68px;
					align-items: center;
					.dot1 {
						width: 30px;
						height: 8px;
						border-radius: 4px;
						background-color: #005ea6;
						margin-right: 5px;
					}
					.dot2 {
						width: 8px;
						height: 8px;
						margin: 0 5px;
						background-color: #222;
						border-radius: 8px;
					}
				}
				.lable {
					width: 38px;
					height: 38px;
					border-radius: 38px;
					background-color: #ffd839;
					text-align: center;
					line-height: 38px;
					position: absolute;
					top: 0;
					right: 0;
				}
				.eye {
					width: 30px;
					height: 30px;
					background-color: #005ea6;
					position: absolute;
					top: 115px;
					right: 115px;
					text-align: center;
					line-height: 30px;
					border-radius: 30px;
					transform-origin: center;
					opacity: 0;
				}
				.eye:hover {
					background-color: #ff5e00;
				}
				.refresh {
					width: 30px;
					height: 30px;
					position: absolute;
					border: 2px solid #005ea6;
					top: 210px;
					right: 15px;
					text-align: center;
					border-radius: 30px;
					line-height: 25px;
					color: #005ea6;
					opacity: 0;
				}
				.refresh:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.love {
					width: 30px;
					height: 30px;
					position: absolute;
					border: 2px solid #005ea6;
					top: 210px;
					right: 50px;
					text-align: center;
					line-height: 25px;
					border-radius: 30px;
					color: #005ea6;
					opacity: 0;
				}
				.love:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.addToCart {
					height: 30px;
					width: 100px;
					position: absolute;
					top: 210px;
					right: 85px;
					text-align: center;
					background-color: #005ea6;
					color: #fff;
					line-height: 30px;
					border-radius: 15px;
					opacity: 0;
				}
				.addToCart:hover {
					background-color: #ff5e00;
				}
			}
			.cat-warp {
				position: relative;
				img {
					width: 200px;
					border-radius: 5px;
					margin-bottom: 10px;
				}
				.box {
					width: 200px;
					height: 138.61px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 5px;
					left: 0;
				}
				.box:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
				.cat-warp-item {
					a {
						font-size: 13px;
						color: #333;
						font-weight: 600;
					}
					a:hover {
						color: #005ea6;
					}
					.cat-warp-data {
						font-size: 12px;
						float: left;
						text-align: left;
						color: #999;
					}
				}
				.el-divider {
					margin-top: 40px;
					margin-bottom: 10px;
				}
			}
			.slider-testimonials {
				width: 200px;
				// background-color: yellow;
				text-align: center;
				border: 1px solid #ccc;
				margin-top: 10px;
				img {
					border: 3px solid #005ea6;
					border-radius: 80px;
					margin-top: 20px;
					width: 80px;
				}
				.name {
					font-size: 14px;
					color: #333;
					font-weight: 600;
					margin: 10px 0 5px 0;
				}
				p {
					color: #777;
					position: relative;
					overflow: hidden;
					padding: 0 30px;
					margin: 30px 0;
					font-size: 13px;
				}
				.top-right {
					flex: 1;
					// background-color: #005ea6;
					display: flex;
					// justify-content: center;
					margin: 30px 0 30px 68px;
					align-items: center;
					.dot1 {
						width: 30px;
						height: 8px;
						border-radius: 4px;
						background-color: #005ea6;
						margin-right: 5px;
					}
					.dot2 {
						width: 8px;
						height: 8px;
						margin: 0 5px;
						background-color: #222;
						border-radius: 8px;
					}
				}
			}
			.banner2 {
				margin-top: 40px;
				position: relative;
				.box {
					width: 200px;
					height: 298.39px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 5px;
					left: 0;
				}
				.box:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
			}
		}
		.right {
			width: 100%;
			height: 1000px;
			// background-color: yellow;
			.static-cates {
				ul {
					display: flex;
					padding-left: 20px;
					li {
						list-style: none;
						padding-right: 20px;
						position: relative;
						img {
							border-radius: 10px;
							width: 180px;
						}
						.box {
							width: 180px;
							height: 195.11px;
							background-color: rgba(0, 0, 0, 0);
							position: absolute;
							top: 0;
							border-radius: 10px;
							left: 0;
						}
						.box:hover {
							background-color: rgba(0, 0, 0, 0.3);
						}
					}
				}
			}
			.modtitle {
				display: flex;
				justify-content: space-between;
				margin: 0 0 20px 20px;
				border-bottom: 1px solid #ccc;
				.modtitle-left {
					height: 50px;
					// background-color: #ff5e00;
					.flash {
						font-size: 20px;
						color: #333;
						font-weight: 700;
						float: left;
						text-transform: uppercase;
						margin-bottom: 4px;
						background-color: transparent;
						padding: 0;
						padding-bottom: 7px;
						padding-top: 10px;
						bottom: 0;
						border-bottom: 3px solid #005ea6;
					}
					.time-kill {
						ul {
							display: flex;
							color: #fff;
							font-weight: 700;
							font-size: 18px;
							margin-top: 10px;
							.num-time {
								background-color: #005ea6;
								padding: 0 10px;
								border-radius: 5px;
							}
							.name-time {
								color: #005ea6;
							}
							li {
								list-style: none;
								padding: 0 5px;
							}
						}
					}
				}
				.modtitle-right {
					width: 100px;
					margin-top: 20px;
					font-size: 14px;
					// background-color: #ff5e00;
				}
			}
			.product-items {
				display: flex;
				overflow: hidden;
				margin-bottom: 20px;
				margin-left: 20px;
				position: relative;
				.fnPrev {
					width: 33px;
					height: 33px;
					border-radius: 33px;
					text-align: center;
					line-height: 33px;
					z-index: 999;
					position: absolute;
					top: 125px;
					left: 0;
					box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
					background-color: #fff;
					opacity: 0;
				}
				.fnPrev:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.fnNext {
					width: 33px;
					height: 33px;
					border-radius: 33px;
					text-align: center;
					line-height: 33px;
					z-index: 999;
					position: absolute;
					top: 125px;
					right: 2px;
					box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
					background-color: #fff;
					opacity: 0;
				}
				.fnNext:hover {
					background-color: #005ea6;
					color: #fff;
				}
        .fnPrev1 {
					width: 33px;
					height: 33px;
					border-radius: 33px;
					text-align: center;
					line-height: 33px;
					z-index: 999;
					position: absolute;
					top: 125px;
					left: 200px;
					box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
					background-color: #fff;
					opacity: 0;
				}
				.fnPrev1:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.fnNext1 {
					width: 33px;
					height: 33px;
					border-radius: 33px;
					text-align: center;
					line-height: 33px;
					z-index: 999;
					position: absolute;
					top: 125px;
					right: 2px;
					box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
					background-color: #fff;
					opacity: 0;
				}
				.fnNext1:hover {
					background-color: #005ea6;
					color: #fff;
				}
        .fnPrev2 {
					width: 33px;
					height: 33px;
					border-radius: 33px;
					text-align: center;
					line-height: 33px;
					z-index: 999;
					position: absolute;
					top: 125px;
					left: 0;
					box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
					background-color: #fff;
					opacity: 0;
				}
				.fnPrev2:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.fnNext2 {
					width: 33px;
					height: 33px;
					border-radius: 33px;
					text-align: center;
					line-height: 33px;
					z-index: 999;
					position: absolute;
					top: 125px;
					right: 200px;
					box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
					background-color: #fff;
					opacity: 0;
				}
				.fnNext2:hover {
					background-color: #005ea6;
					color: #fff;
				}
				a {
					img {
						width: 200px;
					}
				}
				.box {
					width: 200px;
					height: 272px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 10px;
					left: 0;
				}
				.box:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
				.box1 {
					width: 200px;
					height: 272px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 10px;
					right: 0;
				}
				.box1:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
				.product-item:hover .active {
					opacity: 0;
					transition: all 0.5s;
				}
				.product-item:hover .eye {
					opacity: 1;
					animation: change2 0.5s;
				}
				.product-item:hover .love {
					opacity: 1;
					animation: animLoadedHeader2 0.5s;
				}
				.product-item:hover .refresh {
					opacity: 1;
					animation: animLoadedHeader1 0.5s;
				}
				.product-item:hover .addToCart {
					opacity: 1;
					animation: animLoadedHeader3 0.5s;
				}
				.product-item:hover .star {
					opacity: 0;
				}
				.product-item:hover h4 {
					opacity: 0;
				}
				.product-item {
					position: relative;
					width: 185px;
					text-align: center;
					margin-right: 13px;
					a {
						img {
							position: relative;
							width: 185px;
							height: 185px;
						}
						.active {
							// opacity: 0;
							position: absolute;
							top: 0;
							right: 0;
						}
					}
					.star {
						ul {
							display: flex;
							padding-left: 57px;
							margin-bottom: 5px;
							li {
								list-style: none;
								img {
									width: 12px;
									height: 12px;
								}
							}
						}
					}
					h4 {
						font-size: 13px;
						color: #333;
					}
					.money {
						margin-bottom: 5px;
						.now-money {
							color: #005ea6;
							font-size: 16px;
							font-weight: 600;
						}
						.old-money {
							text-decoration: line-through;
							color: #aaa;
						}
					}
					p {
						margin-top: 10px;
						font-size: 12px;
						b {
							font-size: 13px;
							color: #005ea6;
							font-weight: 600;
						}
					}
					.lable {
						width: 38px;
						height: 38px;
						border-radius: 38px;
						background-color: #ffd839;
						text-align: center;
						line-height: 38px;
						position: absolute;
						top: 0;
						right: 0;
					}
					.eye {
						width: 30px;
						height: 30px;
						background-color: #005ea6;
						position: absolute;
						top: 85px;
						right: 85px;
						text-align: center;
						line-height: 30px;
						border-radius: 30px;
						transform-origin: center;
						opacity: 0;
						cursor: pointer;
					}
					.eye:hover {
						background-color: #ff5e00;
					}
					@keyframes change2 {
						from {
							transform: scale(0.5);
							opacity: 0;
						}
						to {
							transform: scale(1);
							opacity: 1;
						}
					}
					@keyframes animLoadedHeader1 {
						to {
							opacity: 1;
							transform: translate3d(0, 0px, 0);
						}
						from {
							opacity: 0;
							transform: translate3d(0, -40px, 0);
						}
					}
					@keyframes animLoadedHeader2 {
						to {
							opacity: 1;
							transform: translate3d(0, 0px, 0);
						}
						from {
							opacity: 0;
							transform: translate3d(0, -45px, 0);
						}
					}
					@keyframes animLoadedHeader3 {
						to {
							opacity: 1;
							transform: translate3d(0, 0px, 0);
						}
						from {
							opacity: 0;
							transform: translate3d(0, -50px, 0);
						}
					}
					.refresh {
						width: 30px;
						height: 30px;
						position: absolute;
						border: 2px solid #005ea6;
						top: 195px;
						right: 15px;
						text-align: center;
						border-radius: 30px;
						line-height: 25px;
						color: #005ea6;
						opacity: 0;
					}
					.refresh:hover {
						background-color: #005ea6;
						color: #fff;
					}
					.love {
						width: 30px;
						height: 30px;
						position: absolute;
						border: 2px solid #005ea6;
						top: 195px;
						right: 50px;
						text-align: center;
						line-height: 25px;
						border-radius: 30px;
						animation: change1 1s;
						color: #005ea6;
						opacity: 0;
					}
					.love:hover {
						background-color: #005ea6;
						color: #fff;
					}
					.addToCart {
						height: 30px;
						width: 100px;
						position: absolute;
						top: 195px;
						right: 85px;
						text-align: center;
						background-color: #005ea6;
						color: #fff;
						line-height: 30px;
						border-radius: 15px;
						opacity: 0;
					}
					.addToCart:hover {
						background-color: #ff5e00;
					}
				}
				.product-item2:hover .active {
					opacity: 0;
					transition: all 0.5s;
				}
				.product-item2:hover .eye {
					opacity: 1;
					animation: change2 0.5s;
				}
				.product-item2:hover .love {
					opacity: 1;
					animation: animLoadedHeader2 0.5s;
				}
				.product-item2:hover .refresh {
					opacity: 1;
					animation: animLoadedHeader1 0.5s;
				}
				.product-item2:hover .addToCart {
					opacity: 1;
					animation: animLoadedHeader3 0.5s;
				}
				.product-item2:hover .star {
					opacity: 0;
				}
				.product-item2:hover h4 {
					opacity: 0;
				}
				.product-item2 {
					position: relative;
					width: 195px;
					text-align: center;
					margin-bottom: 20px;
					a {
						img {
							position: relative;
							width: 195px;
							height: 195px;
						}
						.active {
							// opacity: 0;
							position: absolute;
							top: 0;
							right: 0;
						}
					}
					.star {
						ul {
							display: flex;
							padding-left: 67px;
							margin-bottom: 5px;
							li {
								list-style: none;
								img {
									width: 12px;
									height: 12px;
								}
							}
						}
					}
					h4 {
						font-size: 13px;
						color: #333;
					}
					.money {
						margin-bottom: 5px;
						.now-money {
							color: #005ea6;
							font-size: 16px;
							font-weight: 600;
						}
						.old-money {
							text-decoration: line-through;
							color: #aaa;
						}
					}
					p {
						margin-top: 10px;
						font-size: 12px;
						b {
							font-size: 13px;
							color: #005ea6;
							font-weight: 600;
						}
					}
					.lable {
						width: 38px;
						height: 38px;
						border-radius: 38px;
						background-color: #ffd839;
						text-align: center;
						line-height: 38px;
						position: absolute;
						top: 0;
						right: 0;
					}
					.eye {
						width: 30px;
						height: 30px;
						background-color: #005ea6;
						position: absolute;
						top: 115px;
						right: 115px;
						text-align: center;
						line-height: 30px;
						border-radius: 30px;
						animation: change1 1s;
						transform-origin: center;
						opacity: 0;
						cursor: pointer;
					}
					.eye:hover {
						background-color: #ff5e00;
					}
					.refresh {
						width: 30px;
						height: 30px;
						position: absolute;
						border: 2px solid #005ea6;
						top: 210px;
						right: 15px;
						text-align: center;
						border-radius: 30px;
						line-height: 25px;
						color: #005ea6;
						animation: change1 1s;
						opacity: 0;
					}
					.refresh:hover {
						background-color: #005ea6;
						color: #fff;
					}
					.love {
						width: 30px;
						height: 30px;
						position: absolute;
						border: 2px solid #005ea6;
						top: 210px;
						right: 50px;
						text-align: center;
						line-height: 25px;
						border-radius: 30px;
						color: #005ea6;
						animation: change1 1s;
						opacity: 0;
					}
					.love:hover {
						background-color: #005ea6;
						color: #fff;
					}
					.addToCart {
						height: 30px;
						width: 100px;
						position: absolute;
						top: 210px;
						right: 85px;
						text-align: center;
						background-color: #005ea6;
						color: #fff;
						line-height: 30px;
						border-radius: 15px;
						opacity: 0;
						animation: change1 1s;
					}
					.addToCart:hover {
						background-color: #ff5e00;
					}
				}
			}
			.product-items:hover .fnNext {
				opacity: 1;
				animation: change2 0.5s;
			}
			.product-items:hover .fnPrev {
				opacity: 1;
				animation: change2 0.5s;
			}
      .product-items:hover .fnNext1 {
				opacity: 1;
				animation: change2 0.5s;
			}
			.product-items:hover .fnPrev1 {
				opacity: 1;
				animation: change2 0.5s;
			}
      .product-items:hover .fnNext2 {
				opacity: 1;
				animation: change2 0.5s;
			}
			.product-items:hover .fnPrev2 {
				opacity: 1;
				animation: change2 0.5s;
			}
			.product-banner {
				display: flex;
				margin-bottom: 20px;
				position: relative;
				margin-left: 20px;
				.product-banner1 {
					img {
						width: 250px;
						height: 250px;
					}
				}
				.box1 {
					width: 250px;
					height: 250px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 5px;
					left: 0;
				}
				.box1:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
				.product-banner2 {
					margin: 0 10px;
					img {
						width: 460px;
						height: 250px;
					}
				}
				.product-banner3 {
					img {
						width: 250px;
						height: 250px;
					}
				}
				.box2 {
					width: 460px;
					height: 250px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 5px;
					left: 260px;
				}
				.box2:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
				.box3 {
					width: 250px;
					height: 250px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 5px;
					right: 0;
				}
				.box3:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
			}
			.modtitle2 {
				display: flex;
				border-bottom: 1px solid #ccc;
				margin: 0 0 20px 20px;
				padding-right: 10px;
				justify-content: space-between;
				.modtitle2-left {
					font-size: 20px;
					color: #fff;
					font-weight: 700;
					float: left;
					text-transform: uppercase;
					background-color: #005ea6;
					padding: 0;
					padding-bottom: 7px;
					padding-top: 10px;
					bottom: 0;
				}
				.modtitle2-right {
					li {
						list-style: none;
						float: right;
						font-size: 14px;
						color: #666;
						font-weight: 500;
						line-height: 25px;
						padding-top: 12px;
						padding-left: 25px;
					}
				}
			}
			.btn-image {
				margin-bottom: 20px;
				position: relative;
				margin-left: 20px;
				display: flex;
				justify-content: space-between;
				img {
					width: 480px;
				}
				.box1 {
					width: 480px;
					height: 136.11px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 5px;
					left: 0;
				}
				.box1:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
				.box2 {
					width: 480px;
					height: 136.11px;
					background-color: rgba(0, 0, 0, 0);
					position: absolute;
					top: 0;
					border-radius: 5px;
					right: 0;
				}
				.box2:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}
			}
			::v-deep {
				.el-tabs__item.is-active {
					color: #005ea6;
				}
				.el-tabs__item:hover {
					color: #005ea6;
					cursor: pointer;
				}
				.el-tabs__active-bar {
					background-color: #005ea6;
				}
			}
			.slider-brands {
				// background-color: yellow;
				border: 1px solid #ccc;
				border-radius: 5px;
				position: relative;
				margin-left: 20px;
				.owl2-stage-outer {
					padding: 30px 0;
					// width: 1375px;
					overflow: hidden;
					// background-color: #005ea6;
					.fnPrev {
						width: 33px;
						height: 33px;
						border-radius: 33px;
						text-align: center;
						line-height: 33px;
						position: absolute;
						top: 55px;
						left: -20px;
						z-index: 999;
						box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
						background-color: #fff;
					}
					.fnPrev:hover {
						background-color: #005ea6;
						color: #fff;
					}
					.fnNext {
						width: 33px;
						height: 33px;
						border-radius: 33px;
						text-align: center;
						line-height: 33px;
						position: absolute;
						top: 55px;
						right: -20px;
						z-index: 999;
						box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
						background-color: #fff;
					}
					.fnNext:hover {
						background-color: #005ea6;
						color: #fff;
					}
					.fixedBox {
						flex: 1;
						overflow: hidden;
						.centerScroll {
							display: flex;
						}
					}
				}
			}
		}
	}
}
</style>
